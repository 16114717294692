// main root endpoint
export const API_ROOT = process.env.REACT_APP_BACKEND_URI;
export const EXPERIMENTAL = process.env.REACT_APP_EXPERIMENTAL === "true";
// all api end points
export const API_END_POINTS = {
  register: "api/v2/auth/register",
  login: "api/v2/auth/login",
  authCheck: "api/v2/auth/check",
  authRefreshCaptcha: "api/v2/auth/refresh-captcha",
  authCheckCaptcha: "api/v2/auth/check-captcha",
  changePassword: "api/v2/auth/changePassword",
  createEvent: "api/v2/event/create-event",
  updateEvent: "api/v2/event/update-event",
  getEvent: "api/v2/event/get-one-event",
  getEventList: "api/v2/event/get-all-events",
  getWindowEventList: "api/v2/windowTicket/user-event-list",
  getCategories: "api/v2/event/get-category",
  getLanguages: "api/v2/event/get-language",
  getArtists: "api/v2/artist/dropdown",
  getArtistsList: "api/v2/artist/list",
  addArtists: "api/v2/artist/add",
  updateArtists: "api/v2/artist/update",
  deleteArtists: "api/v2/artist/delete",
  getProfile: "api/v2/auth/getProfile",
  updateProfile: "api/v2/auth/updateProfile",
  verifyEmail: "api/v2/auth/verifyEmail",
  resendVerifyEmail: "api/v2/auth/resend-email-verify",
  createTicket: "api/v2/ticket/ticket-add",
  changeTicketOrder: "api/v2/ticket/update-ticket-order",
  getOrganizers: "api/v2/organizer/list",
  getCustomers: "api/v2/customer/list",
  getCustomer: "api/v2/customer/get",
  getOrganizer: "api/v2/organizer/get",
  getOrganizerDashboard: "api/v2/organizer/dashboard",
  updateStatus: "api/v2/status/change",
  updateBankStatus: "api/v2/payment/update-status",
  updateTicket: "api/v2/ticket/ticket-update",
  updateTicketStatus: "api/v2/ticket/update-status",
  getTermsAndConditionList: "api/v2/event/get-TermsCondition",
  getAgeGroup: "api/v2/ticket/get-age-group",
  organizerDropDown: "api/v2/organizer/drop-down",
  getSalesReportList: "api/v2/report/sales-wise-report",
  getReportList: "api/v2/report/business-report",
  termConditionAdd: "api/v2/terms-condition/add",
  termConditionUpdate: "api/v2/terms-condition/update",
  termConditionList: "api/v2/terms-condition/list",
  termConditionDelete: "api/v2/terms-condition/delete",
  checkerList: "api/v2/checker-panel/get-checker",
  checkerDelete: "api/v2/checker-panel/remove-checker",
  addAndUpdateChecker: "/api/v2/checker-panel/update-checker",
  checkIn: "/api/v2/checker-panel/checkIn",
  checkerReport: "/api/v2/report/checker-wise-report",
  ageReport: "/api/v2/report/age-wise-report",
  sendMessage: "/api/v2/customer/bulk-send",
  addReason: "/api/v2/payment/refund-amount",
  supportList: "api/v2/support/list",
  supportView: "api/v2/support/get",
  getPaymentdetails: "/api/v2/payment/payment-details",
  getDashboardSupport: "/api/v2/support/dashboard",
  addMessageSupport: "/api/v2/support/reply",
  getSingleEvent: "api/v2/report/dashboard",
  getCancelledEventDetails: "api/v2/payment/bulk-payment-details",
  bulkRefund: "api/v2/payment/bulk-refund",
  sponsersList: "api/v2/sponsor/list",
  getTicketTypeList: "api/v1/event/view",
  addSponser: "api/v2/sponsor/buy-tickets",
  updateSponser: "api/v2/sponsor/edit",
  getSingleSponser: "api/v2/sponsor/get",
  getSponserTicket: "api/v1/profile/my-ticket-pdf",
  getCheckersDateList: "api/v2/report/date-list",
  getCheckersTicketList: "api/v2/checker-panel/ticket-type-list",
  getAutoLogin: "api/v2/auth/autoLogin",
  deleteSlot: "api/v2/ticket/remove-slot",
  savePayment: "api/v2/payment/save-bank-details",
  getPayment: "api/v2/payment/get-bank-details",
  updatePayment: "api/v2/payment/update-bank-details",
  notificationList: "api/v2/notification/list",
  notificationRead: "api/v2/notification/update",
  organizerUpdate: "api/v2/organizer/update-status",
  verifyPayment: "api/v2/payment/verify-bank-details",
  listPromoCode: "api/v2/promoCode/list-promoCode",
  addPromoCode: "api/v2/promoCode/add-promoCode",
  viewPromoCode: "api/v2/promoCode/view-promoCode",
  updatePromoCode: "api/v2/promoCode/update-promoCode",
  organizersList: "api/v2/event/organizer-list",
  dashboardList: "/api/v2/organizer/dashboard",
  getPaymentsList: "api/v2/settlement/get",
  addPayment: "api/v2/settlement/add",
  editPayment: "api/v2/settlement/edit",
  setCharges: "api/v2/event/set-charges",
  organizerInHouseStatus: "api/v2/organizer/update-inhouse-status",
  NewDashboard: "api/v2/dashboard/common",
  reportTransaction: "api/v2/report/transaction",
  EventList: "/api/v2/event/dropdown",
  TransactionCards: "api/v2/report/business-report",
  AddWindowUser: "api/v2/windowTicket/add-window-user",
  getWindowUserEndPoint: "api/v2/windowTicket/window-user-list",
  getTicketListEndPoint: "api/v2/windowTicket/tickets-list",
  getTicketItemEndPoint: "api/v2/windowTicket/get-tickets",
  updateWindowUserStatusEndpoint: "api/v2/windowTicket/update-status",
  buyTicketEndpoint: "api/v2/windowTicket/buy-tickets",
  getUserFromMobile: "api/v2/windowTicket/get-user",
  rescheduleEvent: "api/v2/event/reschedule",
  windowReport: "api/v2/windowTicket/get-report",
  sendNotification: "api/v2/notification/send-notification",
  uploadGallery: "api/v2/event/upload-img",
  deleteGallery: "api/v2/event/delete-img",
  windowTicketList: "api/v2/windowTicket/order-list",
  resendTicket: "api/v2/windowTicket/resend-sms",
  deleteBookedTicket: "api/v2/windowTicket/delete-booking",
  windowUserList: "api/v2/windowTicket/user-dropdown",
  stateDropdown: "/api/v2/customer/state-dropdown",
  cityDropdown: "/api/v2/customer/city-dropdown",
  addOrganizerNote: "api/v2/organizer/add-update-note",
  organizerEventList: "api/v2/organizer/event-list",
  getActivityLog: "api/v2/activity-log/get",
  getActivityView: "api/v2/activity-log/view",
  getSimpleEventList: "api/v2/event/simple-list",
  getCustomersCountAPI: "api/v2/event/get-report-customer",
  getSyncCustomer: "api/v2/event/sync-report-customer",
  sendNotificationToCustomer: "api/v2/event/send-notification",
  getStateAndCity: "api/v2/event/state-city-dropdown",
  getTicketReport: "api/v2/report/ticket-report",
  deleteEvent: "api/v2/event",
  getAutoGenerateForm: "api/v2/additional-fields/view",
  addAutoGenerateFormInput: "api/v2/additional-fields/add",
  deleteAutoGenerateFormInput: "api/v2/additional-fields",
  updateAutoGenerateFormInput: "api/v2/additional-fields/update",
  invoiceList: "api/v2/invoice/list",
  invoiceAdd: "api/v2/invoice/add",
  invoiceUpdate: "api/v2/invoice/update",
  invoiceServicesList: "api/v2/invoice/service-list",
  servicesDroopDown: "api/v2/invoice/service-dropdown",
  invoiceView: "api/v2/invoice/view",
  invoiceDone: "api/v2/invoice/set-invoice-done",
  invoiceServiceDelete: "api/v2/invoice/item-remove",
  addServiceApi: "api/v2/invoice/service-add",
  editServiceApi: "api/v2/invoice/item-edit",
  receiptAdd: "api/v2/invoice/receipt-add",
  deleteServices: "api/v2/invoice/service-delete",
  confirmReceiptApi: "api/v2/invoice/payment-clear",
  invoiceDelete: "api/v2/invoice",
  additionalFieldsList: "api/v2/additional-fields/view",
  AddAdditionalField: "api/v1/booking/set-additional-fields",
  // API'S FOR SEAT.IO
  syncWithSeatIo: "api/v2/ticket/sync-seatsio",
  //Blogs
  getBlogList: "api/v2/blog/list",
  addBlog: "api/v2/blog/create",
  updateBlog: "api/v2/blog/update",
  blogCategoryDropdown: "api/v2/blog/dropdown",
  deleteBlog: "api/v2/blog/delete",
  getBlog: "api/v2/blog/get"
};
